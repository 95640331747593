<template>
  <svg :width="width" :height="height"  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_138_5587" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill="#C4C4C4"/>
    </mask>
    <g mask="url(#mask0_138_5587)">
      <path
          d="M12 2C6.48589 2 2 6.48589 2 12C2 17.5141 6.48589 22 12 22C17.5141 22 22 17.5141 22 12C22 6.48589 17.5141 2 12 2ZM12 20.7654C7.16667 20.7654 3.23457 16.8333 3.23457 12C3.23457 7.16667 7.16667 3.23457 12 3.23457C16.8333 3.23457 20.7654 7.16667 20.7654 12C20.7654 16.8333 16.8333 20.7654 12 20.7654ZM18.1437 9.69753C17.9024 9.45623 17.5118 9.45623 17.2709 9.69753L11.9997 14.9683L6.72897 9.69665C6.48767 9.45535 6.09705 9.45535 5.85608 9.69665C5.61478 9.9376 5.61478 10.3286 5.85608 10.5695L11.5633 16.2776C11.6791 16.3934 11.836 16.4583 11.9999 16.4583C12.1639 16.4583 12.3206 16.3932 12.4365 16.2776L18.1442 10.57C18.385 10.3292 18.385 9.93823 18.144 9.69727L18.1437 9.69753Z"
          fill="#1E70BB"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconArrowUp",
  props: {
    width: {
      type: String,
      default: '24'
    },
    height: {
      type: String,
      default: '24'
    }
  }
}
</script>